// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apis: {

    // baseUrl: 'http://localhost/tabc/v2/backend/wp-json/acp/v1',
    // document: 'http://localhost/tabc/v2/backend/',
    // document_path: 'http://localhost/tabc/v2/backend/wp-content/uploads/',
    // auth: 'http://localhost/tabc/v2/backend/',
    baseUrl: 'https://acp-api.provestasoft.com/wp-json/acp/v1',
    document: 'https://acp-api.provestasoft.com/',
    document_path: 'https://acp-api.provestasoft.com/wp-content/uploads/',
    auth: 'https://acp-api.provestasoft.com/'
  },
  // frontUrl:'http://localhost:4200/',
  frontUrl:'https://acp.provestasoft.com/'
    // baseUrl: 'https://acp-api.provestasoft.com/wp-json/acp/v1',
    // document: 'https://acp-api.provestasoft.com/',
    // document_path: 'https://acp-api.provestasoft.com/wp-content/uploads/',
    // auth: 'https://acp-api.provestasoft.com/'
  //},
//  frontUrl:'http://localhost:4200/',
  // frontUrl:'https://acp.provestasoft.com/',


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
